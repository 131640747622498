import { useMemo } from 'react';
import { Col, Container, Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import uniqWith from 'lodash/uniqWith';
import { lensPath, path, set } from 'ramda';
import { v4 as uuidv4 } from 'uuid';

import { ValidNumberField } from 'src/components/UserEnteries';
import { COLLECTED_YEAR, NO_DISTRIBUTOR_GUID, UNASSIGNED_PRICE_BAND_ID } from 'src/constants';
import { Button } from 'src/css/styled-components';
import { useMarkets } from 'src/markets';
import { useMarket } from 'src/markets/hooks/useMarket';
import { useRecords } from 'src/records/hooks/useRecords';
import { type DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import { type DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { type DenormalizedOnPremiseSales } from 'src/records/types/DenormalizedOnPremiseSales';
import {
  type BaseOption,
  type BrandApi,
  type BrandLine,
  BrandLineTypes,
  type BrandOwnerApi,
  type Country,
  type CountryList,
  Market,
  NumberEntryEnum,
  type Sale,
  type SaleApi,
} from 'src/shared';
import { useAppSelector } from 'src/store';
import {
  goToStep1,
  hideAddModal,
  stageNewDistributor,
  updateFormState,
} from 'src/store/features/brandLineAdd/actions';
import { selectSelectedBrandLinesWithUniqueIds } from 'src/store/features/brandLineAdd/selectors';
import { useSyncContext } from 'src/sync';
import type { PriceBandLimitsEntity } from 'src/types';
import type { DistributorOption } from 'src/types/DistributorOption';
import type { Entity } from 'src/types/Entity';
import { type CategoriesEntity } from 'src/types/entity/Categories';
import {
  type BrandLineFormItem,
  type FormValue,
  mapBrandLineToSale,
  mapBrandLineToSaleNewApi,
  updateDistributorIdFormStateWithDefault,
} from 'src/utils/addBrandLineUtils';
import { getPriceDecimalPlaces } from 'src/utils/gridUtils';
import {
  validateContainerSize,
  validateLocalOrImported,
  validatePrice,
  validatePriceBand,
  validateVolume,
} from 'src/utils/validateUtils/validateAddBrandLine';
import { LocalOrImported } from 'src/utils/validateUtils/validateLocalOrImported';
import { useEnsureForecastAndOtherExist } from 'src/views/hooks/useEnsureForecastAndOtherExist';
import { ErrorDiv, Heading, Label } from './styledComponents';
import { isCategoryForecastByOrigin } from './utils/isCategoryForecastByOrigin';
import Creatable from './WindowedCreateable';

interface StepTwoProps {
  closeModal: () => void;
}

interface Segment {
  category5Id: number;
  priceBandId: number;
  isLocal: boolean;
  originId: number;
  isForecastByOrigin: boolean;
}

const StepTwo = ({ closeModal }: StepTwoProps) => {
  const dispatch = useDispatch();
  const { createEntity } = useSyncContext();
  const {
    market: { marketId: country },
  } = useMarket();

  const markets = useMarkets();

  // TODO: at the moment market is always Domestic. Need to find out whether this is needs to dynamically change when market is DF (Duty-Free).
  // Potentially effects things like price validation..
  const market = Market.domestic;
  const currentYear = COLLECTED_YEAR;

  const selected = useAppSelector(selectSelectedBrandLinesWithUniqueIds);
  const formState = useAppSelector(state => state.brandLineAdd.step2?.formState) ?? [];
  const createState = useAppSelector(state => state.brandLineAdd.create);

  const {
    brandLines: newBrandLines = [],
    parentBrands: newParentBrands = [],
    brandOwners: newBrandOwners = [],
    distributors: newDistributors = [],
  } = createState ?? {};

  const distributorsEntity = useRecords('distributors');
  const distributors = distributorsEntity.map((item: Entity) => {
    return {
      distributorGUID: item['distributorGUID'] as string,
      distributorName: item['distributorName'] as string,
      countryId: 0,
      reportingCategory: false,
    };
  });
  const priceBandsEntity = useRecords('priceBands');
  const priceBands = priceBandsEntity.map((item: Entity) => {
    return {
      id: item['priceBandId'] as number,
      name: item['priceBandName'] as string,
      displayOrder: item['displayOrder'] as number,
    };
  });

  const priceBandLimits = useRecords<Partial<PriceBandLimitsEntity>>('priceBandLimits', country);

  const countrySales = useRecords<DenormalizedBrandSales>('brandSales', country);
  const onPremiseSales = useRecords<DenormalizedOnPremiseSales>('onPremiseSales', country);
  const allCategories = useRecords<CategoriesEntity>('categories');

  const { ensureForecastAndOtherExist } = useEnsureForecastAndOtherExist(
    countrySales,
    onPremiseSales
  );

  const handleBack = () => {
    dispatch(goToStep1());
  };

  const itemAllValid = (item: BrandLineFormItem) => {
    const reduceFn = (acc: boolean, value: FormValue) => acc && value.isValid;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result: boolean = Object.values(item).reduce(reduceFn, true);
    return result;
  };

  const getFormHasErrors = () => {
    const reduceFn = (acc: boolean, item: BrandLineFormItem) => acc && itemAllValid(item);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = formState.reduce(reduceFn, true);
    return !result;
  };

  // TODO: Find out if the idea of a priceFormat per country is still a thing. If so, how do we populate this?
  // if not, then probably can remove code using marketCountries...
  const marketCountries: CountryList = [];

  const validate = (brandLine: BrandLine, item: BrandLineFormItem) => {
    let newItem = item;
    //  FIXME:  Don't force country toString()
    const priceFormat = marketCountries.find(
      (i: Country) => i.id === country.toString()
    )?.priceFormat;
    const priceDecimalPlaces = getPriceDecimalPlaces(currentYear.toString(), priceFormat);
    newItem = validatePrice(newItem, priceDecimalPlaces);
    newItem = validateVolume(newItem, market);
    newItem = validateContainerSize(newItem);
    //  FIXME:  Don't force country toString()
    newItem = validateLocalOrImported(
      newItem,
      marketCountries,
      country.toString(),
      brandLine.originName
    );
    newItem = validatePriceBand(newItem);
    return newItem;
  };

  const removeCommas = (n: string) => n.split(',').join('');

  const calculateEquivalentValue = (
    price: string,
    containerSize: string,
    standardSizeInCL: number
  ): number | undefined => {
    const priceNumber = Number.parseFloat(removeCommas(price));
    const sizeNumber = Number.parseFloat(removeCommas(containerSize));

    const value = priceNumber / (sizeNumber / standardSizeInCL);
    if (Number.isNaN(value)) return undefined;
    return value;
  };

  const handleChange =
    (brandLine: BrandLine, property: string, index: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const item = formState[index]!;
      const { value } = e.target;

      let newItem = set(lensPath([property, 'value']), value, item);

      // Validate drop downs on change
      if (property === 'localOrImported' || property === 'distributorGUID') {
        newItem = set(lensPath([property, 'touched']), true, newItem);
        newItem = validate(brandLine, newItem);
      }

      if (property === 'containerSize' || property === 'price') {
        if (
          !Number.isNaN(parseFloat(newItem.price.value.replace('~', ''))) &&
          !Number.isNaN(parseFloat(newItem.containerSize.value))
        ) {
          const priceBandLimitsForThisCategory = priceBandLimits.find(
            ({ category5Id }) => category5Id === selected[index]?.category5Id
          );
          const standardSizeInCL = priceBandLimitsForThisCategory?.standardContainerSize ?? 70;

          const equivalentValue = calculateEquivalentValue(
            newItem.price.value.replace('~', ''),
            newItem.containerSize.value,
            standardSizeInCL
          );

          if (equivalentValue != null && priceBandLimitsForThisCategory?.priceBandLimits?.length) {
            const filteredPriceBandLimit = priceBandLimitsForThisCategory.priceBandLimits
              .filter(({ upperLimit }) => upperLimit >= equivalentValue)
              .map(priceBandLimit => {
                const displayOrder =
                  priceBands.find(priceBand => priceBand.id === priceBandLimit.priceBandId)
                    ?.displayOrder ?? 0;
                return {
                  ...priceBandLimit,
                  displayOrder,
                };
              });

            if (filteredPriceBandLimit.length) {
              const relevantPriceBandLimit = filteredPriceBandLimit.reduce((previous, current) =>
                previous.upperLimit < current.upperLimit ||
                (previous.upperLimit >= current.upperLimit &&
                  previous.displayOrder > current.displayOrder)
                  ? previous
                  : current
              );

              newItem = set(
                lensPath(['priceBandId', 'value']),
                relevantPriceBandLimit.priceBandId,
                newItem
              );
            } else {
              newItem = set(lensPath(['priceBandId', 'value']), '', newItem);
            }
          } else {
            const standardPriceBand = priceBands.find(item => item.name === 'Standard');

            if (standardPriceBand) {
              newItem = set(lensPath(['priceBandId', 'value']), standardPriceBand.id, newItem);
            }
          }
        } else {
          newItem = set(
            lensPath(['priceBandId', 'value']),
            UNASSIGNED_PRICE_BAND_ID.toString(),
            newItem
          );
        }
      }

      dispatch(updateFormState(index, newItem));
    };

  // @ts-expect-error: legacy code
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDistributorChange = (brandLine: BrandLine, index: number) => (option: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { value, label, __isNew__ } = option;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let distributorGUID = value;

    if (__isNew__) {
      distributorGUID = uuidv4();
      const distributor: DistributorOption = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        value: distributorGUID,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        label,
      };
      dispatch(stageNewDistributor(distributor));
    }

    const item = formState[index];
    const newItem = set(lensPath(['distributorGUID', 'value']), distributorGUID, item);
    if (newItem) dispatch(updateFormState(index, newItem));
  };

  const setTouchedOnProperty = (item: BrandLineFormItem, property?: string | undefined) => {
    let newItem = item;
    newItem = set(lensPath(['priceBandId', 'touched']), true, newItem);
    newItem = set(lensPath(['localOrImported', 'touched']), true, newItem);
    newItem = set(lensPath(['distributorGUID', 'touched']), true, newItem);

    if (property) newItem = set(lensPath([property, 'touched']), true, newItem);

    return newItem;
  };

  const handleAdd = () => {
    let valid = true;

    for (let index = 0; index < formState.length; index++) {
      const brandLine = selected[index] as unknown as DenormalizedBrandLine | undefined;
      const item = formState[index];

      if (brandLine && item) {
        let newItem = setTouchedOnProperty(item);
        newItem = validate(brandLine as unknown as BrandLine, newItem);

        if (!itemAllValid(newItem)) valid = false;

        dispatch(updateFormState(index, newItem));
      }
    }

    if (!valid) return;

    const sales: Sale[] = selected.map((brandLine: BrandLine, index: number) => {
      const item = formState[index]!;

      return mapBrandLineToSale({
        brandLine,
        currentYear,
        countryId: country,
        distributors,
        newDistributors,
        priceBands,
        distributorGUID: item.distributorGUID.value,
        priceBandId: item.priceBandId.value,
        localOrImported: item.localOrImported.value,
        volume: item.volume.value,
        price: item.price.value,
        containerSize: item.containerSize.value,
      });
    });

    const salesApi: SaleApi[] = selected.map((brandLine: BrandLine, index: number) => {
      const item = formState[index]!;

      return mapBrandLineToSaleNewApi({
        brandLineGUID: brandLine.brandLineGUID.replace(/^new#/, ''),
        currentYear,
        countryId: country,
        distributorGUID: item.distributorGUID.value,
        priceBandId: item.priceBandId.value,
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-enum-comparison */
        localOrImported: item.localOrImported.value === LocalOrImported.Local ? true : false,
        volume: item.volume.value,
        price: item.price.value,
        containerSize: item.containerSize.value,
      });
    });

    newBrandOwners.forEach(brandOwner => {
      const ownerToAddApi: BrandOwnerApi = {
        ownerGUID: brandOwner.value.replace(/^new#/, ''),
        ownerName: brandOwner.label,
        countryId: country,
        createdIn: country,
      };

      void createEntity('owners', ownerToAddApi);
    });

    newParentBrands.forEach(parentBrand => {
      // find sale that matches for other info
      const sale = sales.find((item: Sale) => item.brandGUID === parentBrand.value);
      if (sale) {
        const brandToCreateApi: BrandApi = {
          brandGUID: parentBrand.value.replace(/^new#/, ''),
          brandName: parentBrand.label,
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          ownerGUID: sale?.ownerGUID,
          brandDisplayName: parentBrand.label,
          countryId: country,
          createdIn: country,
        };

        void createEntity('brands', brandToCreateApi);
      }
    });

    newBrandLines.forEach(brandLine => {
      const brandLineToAdd: Entity = {
        ...brandLine,
        brandLineGUID: brandLine.brandLineGUID.replace(/^new#/, ''),
        brandGUID: brandLine.brandGUID.replace(/^new#/, ''),
        ownerGUID: brandLine.ownerGUID.replace(/^new#/, ''),
        countryId: country,
      };

      void createEntity('brandLines', brandLineToAdd);
    });

    newDistributors.forEach(distributor => {
      const sale = sales.find((item: Sale) => item.distributorGUID === distributor.value);
      if (sale) {
        const newDistributor = {
          countryId: country,
          distributorGUID: distributor.value,
          distributorName: distributor.label,
          reportingCategory: false,
        };

        void createEntity('distributors', newDistributor);
      }
    });

    salesApi.forEach((sale: SaleApi) => {
      void createEntity('brandSales', sale);
    });

    const segments = formState
      .map((item, index) => {
        const brandLine = selected[index] as unknown as DenormalizedBrandLine | undefined;

        if (brandLine && brandLine.brandLineTypeId === BrandLineTypes.BRANDED) {
          const isForecastByOrigin = isCategoryForecastByOrigin(
            allCategories,
            brandLine.category5Id
          );
          return {
            category5Id: brandLine.category5Id,
            priceBandId: parseInt(item.priceBandId.value),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            isLocal: item.localOrImported.value === LocalOrImported.Local,
            originId: brandLine.originId,
            isForecastByOrigin,
          };
        }
        return undefined;
      })
      .filter(Boolean) as Segment[];

    const uniqueSegments = uniqWith(segments, (a, b) => {
      return (
        a.category5Id === b.category5Id &&
        a.priceBandId === b.priceBandId &&
        a.isForecastByOrigin === b.isForecastByOrigin &&
        (a.isForecastByOrigin ? a.originId === b.originId : a.isLocal === b.isLocal)
      );
    });
    uniqueSegments.forEach(segment => {
      ensureForecastAndOtherExist(segment);
    });

    dispatch(hideAddModal());
  };

  const handleBlur = (brandLine: BrandLine, property: string, index: number) => () => {
    const item = formState[index];

    if (item) {
      let newItem = setTouchedOnProperty(item, property);
      newItem = validate(brandLine, newItem);
      dispatch(updateFormState(index, newItem));
    }
  };

  const renderInput = (
    numberEntryEnum: NumberEntryEnum,
    label: string,
    property: string,
    brandLine: BrandLine,
    formItem: BrandLineFormItem,
    index: number,
    id?: string
  ) => {
    // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
    const formValue = path([property], formItem) as FormValue;
    const { touched, value, isValid, message } = formValue;

    return (
      <Form.Group>
        <Label>{label}</Label>

        <ValidNumberField
          numberEntryEnum={numberEntryEnum}
          name={property}
          value={value}
          onChange={handleChange(brandLine, property, index)}
          onBlur={handleBlur(brandLine, property, index)}
          isInvalid={touched && !isValid}
          isValid={touched && isValid}
          data-testid={id}
        />
        <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>
      </Form.Group>
    );
  };

  const getEquivalentPrice = (formItem: BrandLineFormItem, standardSizeInCL: number) => {
    const { price, containerSize } = formItem;
    if (price.isValid && containerSize.isValid) {
      const equivalent = calculateEquivalentValue(
        price.value.replace('~', ''),
        containerSize.value,
        standardSizeInCL
      );
      if (equivalent == null || Number.isNaN(equivalent)) return '';
      return `${equivalent.toFixed(2)}`;
    }
    return '';
  };

  const distributorOptions: DistributorOption[] = useMemo(() => {
    if (distributors.length < 1) {
      return [];
    }

    return distributors
      .reduce<DistributorOption[]>((distrs, { distributorGUID, distributorName }) => {
        if (distributorName) {
          return distrs.concat({
            value: distributorGUID,
            label: distributorName,
          });
        }
        return distrs;
      }, [])
      .concat(newDistributors)
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }, [distributors, newDistributors]);

  const renderBrandLineForm = (brandLine: BrandLine, index: number) => {
    let formItem = formState[index]!;

    if (formItem.localOrImported.value.length === 0) {
      const marketsMatchingDemonym = markets.filter(
        item => item.demonym === brandLine.originName && !item.isDomestic
      );

      if (marketsMatchingDemonym.length) {
        const isLocalMarket = marketsMatchingDemonym.some(({ marketId }) => marketId === country);

        formItem.localOrImported.value = isLocalMarket
          ? LocalOrImported.Local
          : LocalOrImported.Imported;
        formItem.localOrImported.touched = true;
      }
    }

    const priceBandLimitsForThisCategory = priceBandLimits.find(
      ({ category5Id }) => category5Id === selected[index]?.category5Id
    );
    const standardSizeInCL = priceBandLimitsForThisCategory?.standardContainerSize ?? 70;
    const equivalentPrice = getEquivalentPrice(formItem, standardSizeInCL);

    const isOther = brandLine.brandLineTypeId === BrandLineTypes.OTHER.valueOf();

    const formItemUpdatedWithDistributorId = updateDistributorIdFormStateWithDefault(
      formItem,
      brandLine,
      isOther,
      countrySales as unknown as Sale[]
    );

    if (formItemUpdatedWithDistributorId) {
      formItem = formItemUpdatedWithDistributorId;
    }

    const distributorOption = distributorOptions.length
      ? distributorOptions.find(
          (option: BaseOption) =>
            option.value === (!isOther ? formItem.distributorGUID.value : NO_DISTRIBUTOR_GUID)
        )
      : [];

    return (
      <fieldset key={brandLine.brandLineGUID}>
        <legend>{brandLine.brandLineName}</legend>
        <Form.Row>
          <Col lg md={6}>
            {renderInput(
              NumberEntryEnum.Price,
              'Price',
              'price',
              brandLine,
              formItem,
              index,
              'add_brand_price'
            )}
          </Col>
          <Col lg md={6}>
            {renderInput(
              NumberEntryEnum.ContainerSize,
              'Container size (cl)',
              'containerSize',
              brandLine,
              formItem,
              index,
              'add_brand_container_size'
            )}
          </Col>
          <Col lg md={6}>
            <Form.Group>
              <Label>
                Equivalent price for&nbsp;
                {standardSizeInCL}
                cl (standard)
              </Label>
              <Form.Control disabled value={equivalentPrice} />
            </Form.Group>
          </Col>
          <Col lg md={6}>
            <Form.Group>
              <Label>Price band</Label>
              <Form.Control
                as="text"
                isInvalid={formItem.priceBandId.touched && !formItem.priceBandId.isValid}
                isValid={formItem.priceBandId.touched && formItem.priceBandId.isValid}
                disabled
                readOnly
                id="step_two_price_band"
              >
                {priceBands.find(priceBand => priceBand.id === parseInt(formItem.priceBandId.value))
                  ?.name ?? 'No price band matched'}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formItem.priceBandId.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col lg md={6}>
            {renderInput(
              NumberEntryEnum.Volume,
              `${currentYear} volume`,
              'volume',
              brandLine,
              formItem,
              index,
              'step_two_volume'
            )}
          </Col>

          <Col lg md={6}>
            <Form.Group>
              <Label>Place of manufacture *</Label>
              <Form.Control
                as="select"
                onChange={handleChange(brandLine, 'localOrImported', index)}
                onBlur={handleBlur(brandLine, 'localOrImported', index)}
                value={formItem.localOrImported.value}
                isInvalid={formItem.localOrImported.touched && !formItem.localOrImported.isValid}
                isValid={formItem.localOrImported.touched && formItem.localOrImported.isValid}
                id="step_two_place_of_manufacture"
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="Local">Local</option>
                <option value="Imported">Imported</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formItem.localOrImported.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg md={6}>
            <Form.Group>
              <Label>Distributor *</Label>
              <Creatable
                options={distributorOptions}
                onChange={handleDistributorChange(brandLine, index)}
                value={distributorOption}
                id="step_two_distributor"
                isDisabled={isOther}
                inputId={'distributorInput'}
              />
            </Form.Group>
          </Col>
          <Col lg md={6} />
        </Form.Row>
      </fieldset>
    );
  };

  const formHasErrors = getFormHasErrors();

  const getAddText = () => {
    let message = 'Adding: ';
    message += selected.length;
    message += selected.length === 1 ? ' sale line' : ' sale lines';

    if (newBrandLines.length > 0) {
      message += `, ${newBrandLines.length} brand `;
      message += newBrandLines.length === 1 ? 'line' : 'lines';
    }

    if (newParentBrands.length > 0) {
      message += `, ${newParentBrands.length} parent `;
      message += newParentBrands.length === 1 ? 'brand' : 'brands';
    }

    if (newBrandOwners.length > 0) {
      message += `, ${newBrandOwners.length} brand `;
      message += newBrandOwners.length === 1 ? 'owner' : 'owners';
    }

    return message;
  };
  return (
    <Container>
      <Modal.Body>
        <Heading>Step 2. Add brand line data</Heading>
        <Form noValidate>{selected.map(renderBrandLineForm)}</Form>
      </Modal.Body>
      <Modal.Footer>
        {formHasErrors && <ErrorDiv>Correct errors above</ErrorDiv>}
        <strong>{getAddText()}</strong>
        <Button type="button" variant="link" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="button"
          data-testid="step_two_back_to_step1"
          variant="secondary"
          onClick={handleBack}
        >
          Back to Step 1
        </Button>
        <Button
          type="submit"
          data-testid="step_two_add_brand_data"
          variant="primary"
          disabled={formHasErrors}
          onClick={handleAdd}
        >
          Add brand data
        </Button>
      </Modal.Footer>
    </Container>
  );
};

export default StepTwo;
